<div class="bg-top-title" *ngIf="userType == userTypeEnum.COMPANY && finalContractSummary.status == contractStatusEnum.WORKER_ACCEPTED">
  <div class="container">
    <div class="row">
      <div class="col py-4 align-items-center">
        <div class="col-12 p-0 mb-5 fs-6">
          {{
            "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.SUBTITLE"
              | translate
          }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container step-up">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12 col-md-12 ps-0">
          <div class="card">
            <div class="card-body">
              <div
                class="d-flex align-items-center justify-content-between border-bottom"
              >
                <h3 class="fw-bold">
                  {{
                    "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.TITLE"
                      | translate
                  }}
                </h3>
              </div>

              <div class="d-flex text-color-dark fs-6 row mt-4">

                <div class="col-12 col-sm-4" *ngIf="finalContractSummary.worker">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.WORKER" | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.worker ? finalContractSummary.worker : "---" }}
                  </p>
                </div>

                <div class="col-12 col-sm-4" *ngIf="finalContractSummary.client">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.CLIENT" | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.client ? finalContractSummary.client : "---" }}
                  </p>
                </div>


                <div class="col-12 col-sm-4">
                  &nbsp;
                </div>
                <div class="col-12 col-sm-4">
                  <button (click)="goContract()" class="btn btn-green" *ngIf="finalContractSummary.contractWnId">
                    {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.CONTRACT_DETAILS" | translate }}
                  </button>
                </div>
              </div>

              <div class="d-flex text-color-dark fs-6 row mt-4">
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.SECTOR"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.commercialOffer?.sector ? ("MAPPINGS.sectors." + finalContractSummary.commercialOffer?.sector | translate) : "---" }}
                  </p>
                </div>
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.TASK"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.commercialOffer?.task || "---" }}
                  </p>
                </div>
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.WORK_PERIOND"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.WORK_FROM"
                        | translate
                    }}
                    {{
                      (finalContractSummary.start | date : "dd/MM/yyyy HH:mm") || "---"
                    }}<br>
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.WORK_TO"
                        | translate
                    }}
                    {{
                      (finalContractSummary.end | date : "dd/MM/yyyy HH:mm") || "---"
                    }}
                  </p>
                </div>
              </div>

              <div class="d-flex text-color-dark fs-6 row">
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.WORKING_TYPE"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    <span
                      *ngIf="!finalContractSummary.timetable?.partTimePercent"
                    >
                      {{ "UTILS.FULL_TIME" | translate }}</span
                    >
                    <span
                      *ngIf="finalContractSummary.timetable?.partTimePercent"
                    >
                      {{ "UTILS.PART_TIME" | translate }}</span
                    >
                    -
                    {{ finalContractSummary.timetable?.hhWeek || "---" }}
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.WEEK_HOURS"
                        | translate
                    }}
                  </p>
                </div>
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.TIME_HOURS"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold mb-0">
                    {{ finalContractSummary.timetable?.hhDay || "---" }}
                  </p>
                </div>

                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.WORK_PLACE"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.jobLocation?.address || "---" }} {{ finalContractSummary.jobLocation?.municipality ? ' - ' + ('MAPPINGS.municipalities.'+finalContractSummary.jobLocation?.municipality | translate) : '' }} {{ finalContractSummary.jobLocation?.district ? '(' + finalContractSummary.jobLocation?.district +')' : '' }}
                  </p>
                </div>

                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.MEAL_TICKET"
                        | translate
                    }}
                  </p>

                  <p
                    class="fw-bold"
                    *ngIf="finalContractSummary.mealVoucher"
                  >
                    {{ "UTILS.YES" | translate }}
                  </p>
                  <p
                    class="fw-bold"
                    *ngIf="!finalContractSummary.mealVoucher"
                  >
                    {{ "UTILS.NO" | translate }}
                  </p>
                </div>
                <div class="col-12 col-sm-4" *ngIf="finalContractSummary.mealVoucher">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.MEAL_TICKET_VALUE"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold">
                  {{finalContractSummary.mealVoucher}}
                  {{
                    "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_COST.EURO"
                      | translate
                  }}
                  </p>
                </div>
              </div>

              <div class="d-flex text-color-dark fs-6 row" *ngIf="substitutionNoteFeatureEnabled && finalContractSummary.substitutionNotes">
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.DOCUMENTS.TAB.CONTRACTS.DIALOG.RENEWAL.FORM.SUBSTITUTION_NOTE.LABEL"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold mb-0">
                    {{ finalContractSummary.substitutionNotes }}
                  </p>
                </div>
              </div>

              <div class="d-flex text-color-dark fs-6 row" *ngIf="finalContractSummary.commercialOffer?.ccnl || finalContractSummary.commercialOffer?.ccnlLevel ">
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.CCNL"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold mb-0">
                    {{ finalContractSummary.commercialOffer?.ccnl || "---" }}
                  </p>
                </div>

                <div class="col-12 col-sm-4" *ngIf="finalContractSummary.commercialOffer?.ccnlLevel">
                  <p class="mb-1">
                    {{
                      "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.CCNL_LEVEL"
                        | translate
                    }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.commercialOffer?.ccnlLevel || "---"  }}
                  </p>
                </div>
              </div>

              <div class="d-flex text-color-dark fs-6 row mt-1" *ngIf="finalContractSummary.repetition">
                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.REPETITION_REASON" | translate }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.repetition.reasonDescription }}
                  </p>
                </div>

                <div class="col-12 col-sm-4">
                  <p class="mb-1">
                    {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_SUMMARY.REPETITION_NOTES" | translate }}
                  </p>
                  <p class="fw-bold">
                    {{ finalContractSummary.repetition.notes }}
                  </p>
                </div>
              </div>
            </div>

            <div class="fs-6 row mt-4">
              <div>
                <div class="card-body">
                  <div
                    class="d-flex align-items-center justify-content-between border-bottom"
                  >
                    <h3 class="fw-bold">
                      {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_TIMESHEET.TITLE" | translate }}
                    </h3>
                  </div>
                  <div class="row my-4">
                    <div align="center" class="col-2">
                      {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_TIMESHEET.FIRST_TIME" | translate }}
                    </div>
                    <div align="center" class="col-2">
                      {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_TIMESHEET.SECOND_TIME" | translate }}
                    </div>
                    <div align="center" class="col-1">
                      {{ 'COMMON.SHORTEST_WEEKDAYS.MONDAY' | translate }}
                    </div>
                    <div align="center" class="col-1">
                      {{ 'COMMON.SHORTEST_WEEKDAYS.TUESDAY' | translate }}
                    </div>
                    <div align="center" class="col-1">
                      {{ 'COMMON.SHORTEST_WEEKDAYS.WEDNESDAY' | translate }}
                    </div>
                    <div align="center" class="col-1">
                      {{ 'COMMON.SHORTEST_WEEKDAYS.THURSDAY' | translate }}
                    </div>
                    <div align="center" class="col-1">
                      {{ 'COMMON.SHORTEST_WEEKDAYS.FRIDAY' | translate }}
                    </div>
                    <div align="center" class="col-1">
                      {{ 'COMMON.SHORTEST_WEEKDAYS.SATURDAY' | translate }}
                    </div>
                    <div align="center" class="col-1">
                      {{ 'COMMON.SHORTEST_WEEKDAYS.SUNDAY' | translate }}
                    </div>
                  </div>

                  <div class="row my-4" *ngFor="let row of finalContractSummary.timetable?.timesheet">
                    <div align="center" class="col-2">
                      {{row.from1}} - {{row.to1}}
                    </div>
                    <div align="center" class="col-2">
                      <span *ngIf="row.from2 && row.to2">{{row.from2}} - {{row.to2}}</span>
                    </div>
                    <div align="center" class="col-1">
                      <tui-svg class="icon {{row.monday ? 'icon-check' : 'icon-close'}}" [src]="row.monday ? 'tuiIconCheck' : 'tuiIconClose'"></tui-svg>
                    </div>
                    <div align="center" class="col-1">
                      <tui-svg class="icon {{row.tuesday ? 'icon-check' : 'icon-close'}}" [src]="row.tuesday ? 'tuiIconCheck' : 'tuiIconClose'"></tui-svg>
                    </div>
                    <div align="center" class="col-1">
                      <tui-svg class="icon {{row.wednesday ? 'icon-check' : 'icon-close'}}" [src]="row.wednesday ? 'tuiIconCheck' : 'tuiIconClose'"></tui-svg>
                    </div>
                    <div align="center" class="col-1">
                      <tui-svg class="icon {{row.thursday ? 'icon-check' : 'icon-close'}}" [src]="row.thursday ? 'tuiIconCheck' : 'tuiIconClose'"></tui-svg>
                    </div>
                    <div align="center" class="col-1">
                      <tui-svg class="icon {{row.friday ? 'icon-check' : 'icon-close'}}" [src]="row.friday ? 'tuiIconCheck' : 'tuiIconClose'"></tui-svg>
                    </div>
                    <div align="center" class="col-1">
                      <tui-svg class="icon {{row.saturday ? 'icon-check' : 'icon-close'}}" [src]="row.saturday ? 'tuiIconCheck' : 'tuiIconClose'"></tui-svg>
                    </div>
                    <div align="center" class="col-1">
                      <tui-svg class="icon {{row.sunday ? 'icon-check' : 'icon-close'}}" [src]="row.sunday ? 'tuiIconCheck' : 'tuiIconClose'"></tui-svg>
                    </div>
                  </div>
                  <div class="row my-4" *ngIf="finalContractSummary.timetable?.description">
                    <div class="col-12">
                      <p class="mb-1">
                        {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_TIMESHEET.NOTES" | translate }}
                      </p>
                      <p class="fw-bold">
                        {{ finalContractSummary?.timetable?.description }}
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>


            <div class="fs-6 row mt-4" *ngIf="userType === userTypeEnum.COMPANY">
              <div>
                <div class="card-body">
                  <div
                    class="d-flex align-items-center justify-content-between border-bottom"
                  >
                    <h3 class="fw-bold">
                      {{
                        "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_COST.TITLE"
                          | translate
                      }}
                    </h3>
                  </div>



                  <div class="row my-4">
                    <div class="col-md-6 col-12 fw-bold mt-2">
                      {{
                        "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_COST.SUBTITLE"
                          | translate
                      }}
                    </div>

                    <div class="col-md-6 col-12 text-center">
                      <h3 class="fw-bold">
                        {{
                          (finalContractSummary.cost
                            | decimalComa) || "---"
                        }}
                        {{
                          "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_COST.EURO"
                            | translate
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="row my-4">
                    <div class="col-12 col-sm-6">
                      <b>{{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_PAY_TYPE.TITLE" | translate }}:</b>
                      {{ "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_PAY_TYPE." + finalContractSummary.paymentMode | translate }} 
                    </div>
                </div>
                </div>
              </div>
            </div>
         
            <div class="mt-4"  *ngIf="(userType == userTypeEnum.WORKER && finalContractSummary.status == contractStatusEnum.PROPOSED) || (userType == userTypeEnum.COMPANY && (!finalContractSummary.status || finalContractSummary.status == contractStatusEnum.WORKER_ACCEPTED))">
              <!--FLAG PAGAMENTO-->
              <form [formGroup]="termsForm">
                <tui-checkbox-labeled
                  formControlName="termsFlag"
                  class=""
                >
                  {{ "REGISTER.WORKER.PRIVACY.TERMS.TEXT" | translate }}
                  <a (click)="viewTerms()" class="privacy-link">{{
                    "REGISTER.WORKER.PRIVACY.TERMS.LINK" | translate
                  }}</a>
                  {{
                    "REGISTER.WORKER.PRIVACY.TERMS.TEXT2" | translate
                  }}
                  <b>*</b>
                </tui-checkbox-labeled>
                <small
                  *ngIf="
                    termsForm.get('termsFlag').touched &&
                    termsForm.get('termsFlag').errors?.required
                  "
                  role="alert"
                  aria-label="Validation error"
                  class="color-status-danger"
                  ><strong>
                    {{ "VALIDATIONS.REQUIRED" | translate }}
                  </strong></small
                >
              </form>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col-6">
                  <button (click)="goBack.emit(true)" class="btn btn-green-outline">
                    <tui-svg src="tuiIconChevronLeftLarge"></tui-svg>
                    {{ "UTILS.BACK" | translate }}
                  </button>
                </div>
                <div class="col-6 text-end" *ngIf="userType == userTypeEnum.COMPANY">
                  <ng-container *ngIf="finalContractSummary.status == contractStatusEnum.WORKER_ACCEPTED">
                    <button
                      [disabled]="!termsForm.valid"
                      (click)="onGoPayment()"
                      class="btn btn-green right"
                    >
                      <tui-svg src="tuiIconChevronRightLarge"></tui-svg>
                      {{ "UTILS.PROCEED_PAYMENT" | translate }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="!finalContractSummary.status">
                    <button
                      [disabled]="!termsForm.valid"
                      (click)="onConfirm.next(true)"
                      class="btn btn-green right"
                    >
                      <tui-svg src="tuiIconChevronRightLarge"></tui-svg>
                      {{ "UTILS.CONFIRM" | translate }}
                    </button>
                  </ng-container>
                </div>
                <div class="col-6 text-end" *ngIf="userType == userTypeEnum.WORKER && finalContractSummary.status == contractStatusEnum.PROPOSED">
                  <button
                    [disabled]="!termsForm.valid"
                    (click)="onRejectEvent()"
                    class="btn btn-green right margin-left"
                  >
                    <tui-svg src="tuiIconCloseLarge"></tui-svg>
                    {{ "UTILS.REFUSE" | translate }}
                  </button>

                  <button
                    [disabled]="!termsForm.valid"
                    (click)="onAcceptEvent()"
                    class="btn btn-green right"
                  >
                    <tui-svg src="tuiIconCheckLarge"></tui-svg>
                    {{ "UTILS.ACCEPT" | translate }}
                  </button>
                </div>

                </div>
              </div>
              <div class="text-end font-14">
                {{
                  "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_PAY_TYPE.CREDIT_CARD.CONTACT"
                    | translate
                }}
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #templateModalPrivacy let-observer let-data="data">
  {{
    "PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_CONDITION.TEXT_MORE"
      | translate
  }}
</ng-template>

<ng-template let-observer #templateWorkerNotContractualizable>
  <div class="mb-2 justify-content-center pb-mobile">
      {{ "PRIVATE.COMMON.AVAILABILITY.NOT_CONTRACTUALIZABLE.TEXT" | translate }}
  </div>
  <div class="row">
      <div align="center" class="col-12 justify-content-center pt-3">
          <button
              (click)="redirectToProfile()"
              class="btn btn-green">
              {{ "PRIVATE.COMMON.AVAILABILITY.ACTIONS_CONFIRM.BTN_GO_PROFILE" | translate }}
          </button>
      </div>
  </div>
</ng-template>
