import {
  Component,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { WorkerStatus } from 'src/app/core/auth/interfaces/worker.model';
import { UserService } from 'src/app/core/auth/services/user.service';
import { UtilityService } from 'src/app/core/utility.service';
import { Form, SelectInput, TextInput } from 'src/app/shared/form';
import { SliderInput } from 'src/app/shared/form/models/inputs/slider-input';
import {
  Announcement,
  SearchAnnouncementsList
} from 'src/app/shared/interfaces/search-announcements-list';
import {
  FilterOrderBy,
  SearchAnnouncementsFiltersResponse
} from 'src/app/shared/interfaces/search-filters';
import { SearchAnnouncementsFilterService } from 'src/app/shared/services/search-announcements-filter.service';
import {
  PartnersResponse,
  SearchAnnouncementsService
} from '../../../services/search-announcements.service';
import { GeneralRegisterOverlayComponent } from '../../overlay/general-register-overlay/general-register-overlay.component';
import { OverlayService } from '../../overlay/overlay.service';

@UntilDestroy()
@Component({
  selector: 'app-search-announcements',
  templateUrl: './search-announcements.component.html',
  styleUrls: ['./search-announcements.component.scss'],
})
export class SearchAnnouncementsComponent implements OnInit, OnDestroy {
  @Input() isPublic: boolean = false;
  filterForm: Form;
  activeFilter: Array<any> = [];
  filter: SearchAnnouncementsFiltersResponse;
  annuncementList: Announcement[];
  pageNumber = 0;
  size = 5;
  totalElements = 0;
  user: any;
  suspendSearch: Boolean;
  enqueuedChanges: Boolean;
  openSector = false;
  openExpirience = false;
  openAvailability = false;
  presetFilter: any;

  index = 0;
  parteners: PartnersResponse[] = [];
  partenerUuid = '';
  currentPartener: PartnersResponse = null;

  suggestedList: Announcement[] = [];

  publicAnnouncementsList = []
  cities = []

  get WorkerStatus() {
    return WorkerStatus;
  }

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    private i18n: TranslateService,
    private router: Router,
    private searchFilterService: SearchAnnouncementsFilterService,
    private searchService: SearchAnnouncementsService,
    private loading: NgxSpinnerService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    public userService: UserService,
    private utilityService: UtilityService,
  ) {}
  publicOnly : boolean;
  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.cities = this.utilityService.getCitiesList();
    this.route.data.subscribe(
      (data: {
        searchInfo: { filters: SearchAnnouncementsFiltersResponse };
        suggestedAnnouncements: Announcement[];
        publics: boolean;
      }) => {
        this.publicOnly = data.searchInfo.filters.publicOnly
        this.filter = data.searchInfo.filters;
        this.partenerUuid = '';
        if (this.filter.partener) {
          this.loading.show();
          this.partenerUuid = this.filter?.partener;
        }
        this.suggestedList = data?.suggestedAnnouncements || [];
        this.suspendAndAccumulateRequests();
        this._initFilterForm();
        this.resumeRequests();
        this.presetFilter = this.searchFilterService.getFilterList();
        this.presetFilters();
      }
    );

    if (!this.isPublic) {
      this.getParteners();
    }

    this.getPubAnnounces();
     
  }

  getPubAnnounces(){
    this.loading.show();
    this.searchService
      .getPubAnnouncements()
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe((res: any) => {
        this.publicAnnouncementsList = res
        if(this.publicOnly)
          this.annuncementList = this.publicAnnouncementsList
      });

  }

  onSubmit() {
    this.pageNumber = 0;
    this.search();
  }

  getTotalPages() {
    return Math.ceil(this.totalElements / this.size);
  }

  getParteners() {
    this.loading.show();
    this.searchService
      .getParteners()
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.parteners = res;
        if (this.partenerUuid) {
          this.parteners.forEach((part) => {
            if (this.partenerUuid === part.uuid) {
              this.currentPartener = part;
            }
          });
          this.search();
        }
      });
  }

  onClick(dropdown) {
    // TODO improve dropdwon mgmt
    if (dropdown === 'dropdownSectorContent') {
      this.openSector = !this.openSector;
      this.openExpirience = false;
      this.openAvailability = false;
    } else if (dropdown === 'dropdownExpirienceContent') {
      this.openExpirience = !this.openExpirience;
      this.openSector = false;
      this.openAvailability = false;
    } else if (dropdown === 'dropdownAvailabilityContent') {
      this.openAvailability = !this.openAvailability;
      this.openExpirience = false;
      this.openSector = false;
    }
  }

  closeDropdowns() {
    this.openExpirience = false;
    this.openSector = false;
    this.openAvailability = false;
  }

  private _initFilterForm() {
    this.filterForm = new Form({
      header: {
        show: false,
      },
      controls: {
        what: new TextInput({
          type: 'text',
          // required: this.partenerUuid ? false : true,
          label: 'SEARCH.FORM.WHAT.LABEL',
          placeholder: 'SEARCH.FORM.WHAT.PLACEHOLDER',
          pattern: /.*\S.*/,
        }),
        where: new TextInput({
          type: 'text',
          label: 'SEARCH.FORM.WHERE.LABEL',
          placeholder: 'SEARCH.FORM.WHERE.PLACEHOLDER',
        }),
        region: new SelectInput<string, string>({
          label: 'SEARCH.FORM.REGION.LABEL',
          placeholder: 'SEARCH.FORM.REGION.PLACEHOLDER',
          options: of(
            this.filter.region.map((reg) => ({
              cod: reg,
              des: this.i18n.instant('MAPPINGS.regions')[reg], //TODO: sbi i18n
            }))
          ),
          bindValue: false,
          valueChange: (value) => {
            if (value) {
              this.filterForm
                .get('region')
                .setValue(value, { emitEvent: false });
              this.changeFilters('region');
            }
          },
        }),
        distance: new SliderInput({
          label: 'SEARCH.FORM.DISTANCE.LABEL',
          placeholder: 'SEARCH.FORM.DISTANCE.PLACEHOLDER',
          min: this.filter.distanceMin,
          max: this.filter.distanceMax,
          segments: 1,
          labels: [
            `${this.filter.distanceMin} ${this.i18n.instant(
              'UTILS.MISURA_DISTANZA'
            )}`,
            `${this.filter.distanceMax} ${this.i18n.instant(
              'UTILS.MISURA_DISTANZA'
            )}`,
          ],
        }),
        sector: new SelectInput<any, string>({
          label: 'SEARCH.FORM.SECTOR.LABEL',
          placeholder: 'SEARCH.FORM.SECTOR.PLACEHOLDER',
          options: of(
            this.filter.sector.map((sec) => ({
              cod: sec,
              des: this.i18n.instant('MAPPINGS.sectors')[sec], //TODO: sbi i18n
            }))
          ),
          bindValue: false,
          valueChange: (value) => {
            if (value) {
              this.filterForm
                .get('sector')
                .setValue(value, { emitEvent: false });
              this.changeFilters('sector');
            }
          },
        }),
        orderBy: new SelectInput<FilterOrderBy, string>({
          label: 'SEARCH.FORM.ORDERBY.LABEL',
          options: of([
            { cod: FilterOrderBy.COMPATIBILITA, des: 'Compatibilità' },
            { cod: FilterOrderBy.DATA, des: 'Data' },
            { cod: FilterOrderBy.DISTANZA, des: 'Distanza' },
          ]),
          value: FilterOrderBy.COMPATIBILITA,
          valueChange: (cod) => {
            this.filterForm.get('orderBy').setValue(cod, { emitEvent: false });
            this.changeFilters('orderBy');
          },
        }),
      },
    });
  }

  presetFilters() {
    let what;
    if (this.presetFilter?.what?.length > 0) {
      what = this.presetFilter.what;
    }
    const previousWhat = this.filterForm.get('what').value;
    this.filterForm.get('what').setValue(what);
    if (previousWhat && previousWhat != what) {
      this.changeFilters('what');
    }
    if (this.presetFilter?.where) {
      if(typeof this.presetFilter.where === 'string') {
        this.filterForm.get('where').setValue(this.presetFilter.where);
      } else {
        this.filterForm.get('where').setValue(this.presetFilter.where.des);
      }
    }

    if (this.presetFilter?.sector) {
      this.filterForm.get('sector').setValue(this.presetFilter.sector);
    }

    if (this.presetFilter?.distance) {
      this.filterForm.get('distance').setValue(this.presetFilter.distance);
    }

    if (this.presetFilter?.orderBy?.length > 0) {
      this.filterForm.get('orderBy').setValue(this.presetFilter.orderBy);
    }
    if (this.presetFilter && Object.keys(this.presetFilter).length)
      this.search();
  }

  onObscured(obscuredE: Event) {
    var obscured = obscuredE as unknown as boolean;
    if (obscured) {
      this.openSector = false;
      this.openExpirience = false;
      this.openAvailability = false;
    }
  }

  onActiveZone(activeE: Event) {
    var active = activeE as unknown as boolean;
    this.openSector = active && this.openSector;
    this.openExpirience = active && this.openExpirience;
    this.openAvailability = active && this.openAvailability;
  }

  search(): void {
    if(this.publicOnly){
      this.annuncementList = this.publicAnnouncementsList
      return
    }
    this.loading.show();
    this.closeDropdowns();
    this.searchFilterService.setFilterList(this.filterForm.value);
    if (this.partenerUuid) {
      this.searchService
        .searchPartners(
          this.filterForm.value,
          this.partenerUuid,
          this.pageNumber
        )
        .pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((resp: SearchAnnouncementsList) => {
          this.annuncementList = resp.details;
          this.size = resp.elementForPage;
          this.totalElements = resp.totalElement;
        });
    } else {
      this.searchService
        .search(this.filterForm.value, this.pageNumber + 1, this.isPublic)
        .pipe(
          map((resp: SearchAnnouncementsList) => {
            return {
              ...resp,
              details: [
                ...resp.details.map((announcement: Announcement) => {
                  return {
                    ...announcement,
                    compatibility: Math.min(announcement.compatibility, 100),
                  };
                }),
              ],
            };
          }),
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((resp: SearchAnnouncementsList) => {
          this.annuncementList = resp.details;
          this.size = resp.elementForPage;
          this.totalElements = resp.totalElement;
        });
    }
  }

  searchPartenes(uuid) {
    this.partenerUuid = uuid;
    this.parteners.forEach((part) => {
      if (this.partenerUuid === part.uuid) {
        this.currentPartener = part;
      }
    });
    this.search();
  }

  goToDetail = (id: number) => {
    if (this.isPublic) {
      if(this.publicAnnouncementsList.filter(e => e.id == id).length > 0){
        this.router.navigate([
          'candidato',
          'cerca-annunci',
          'search-detail',
          id,
        ]);
      }else{
        if (this.partenerUuid){
          this.userService.setRequestedRoute(['private', 'candidato', 'cerca-annunci', 'search-detail', id],  { queryParams: { partener: this.partenerUuid }})
        }else{
          this.userService.setRequestedRoute(['private', 'candidato', 'cerca-annunci', 'search-detail', id])
        }
        this.overlayService.generalLoginOverlayOpen();
      }
    }else{
      this.searchFilterService.setFilterList(this.filterForm.value);
        if (this.partenerUuid) {
          this.router.navigate(
            ['private', 'candidato', 'cerca-annunci', 'search-detail', id],
            { queryParams: { partener: this.partenerUuid } }
          );
        } else {
          this.router.navigate([
            'private',
            'candidato',
            'cerca-annunci',
            'search-detail',
            id,
          ]);
        }
    }
  };

  clearFilter(filter): void {
    if (filter.type !== 'orderBy') {
      this.filterForm.get(filter.type).setValue('', { emitEvent: false });
    } else {
      this.filterForm
        .get(filter.type)
        .setValue(FilterOrderBy.COMPATIBILITA, { emitEvent: false });
    }
    this.searchFilterService.setFilterList(this.filterForm.value);
    this.changeFilters(filter.type);
  }

  clearAllFilters(): void {
    this.filterForm.get('what').setValue('', { emitEvent: false });
    this.filterForm.get('where').setValue('', { emitEvent: false });
    this.filterForm.get('region').setValue('', { emitEvent: false });
    this.filterForm.get('distance').setValue('', { emitEvent: false });
    this.filterForm.get('sector').setValue('', { emitEvent: false });
    this.filterForm
      .get('orderBy')
      .setValue(FilterOrderBy.COMPATIBILITA, { emitEvent: false });
    this.activeFilter = [];
    this.searchFilterService.setFilterList(this.filterForm.value);
    this.pageNumber = 0;
  }

  apply = (id) => {
    if (this.isPublic) {
      if (this.partenerUuid){
        this.userService.setRequestedRoute(['private', 'candidato', 'cerca-annunci', 'search-detail', id, 'send-application'],  { queryParams: { partener: this.partenerUuid }})
      }else{
        this.userService.setRequestedRoute(['private', 'candidato', 'cerca-annunci', 'search-detail', id, 'send-application'])
      }
      this.overlayService.generalLoginOverlayOpen();
    } else if (this.partenerUuid) {
      this.router.navigate(
        [
          'private',
          'candidato',
          'cerca-annunci',
          'search-detail',
          id,
          'send-application',
        ],
        { queryParams: { partener: this.partenerUuid } }
      );
    } else {
      this.router.navigate([
        'private',
        'candidato',
        'cerca-annunci',
        'search-detail',
        id,
        'send-application',
      ]);
    }
  };

  goToSearch = (keyword, address) => {
    this.filterForm.get('what').setValue(keyword);
    this.filterForm.get('where').setValue(address);
    this.searchFilterService.setFilterList(this.filterForm.value);
    this.pageNumber = 0;
    this.search();
  };

  suspendAndAccumulateRequests(): void {
    this.suspendSearch = true;
  }

  resumeRequests(): void {
    if (this.enqueuedChanges) {
      this.enqueuedChanges = false;
      this.search();
    }
    this.suspendSearch = false;
  }

  changeFilters(filterName): void {
    if (this.suspendSearch) {
      this.enqueuedChanges = true;
      return;
    }
    if (filterName === 'what') {
      this.activeFilter = this.activeFilter.filter((obj) => {
        if (obj.type !== 'what') {
          return obj;
        }
      });
      if (this.filterForm.value[filterName]) {
        this.activeFilter.push({
          type: 'what',
          label: 'SEARCH.FORM.WHAT.LABEL',
          value: this.filterForm.value[filterName],
        });
      }
    } else if (filterName === 'where') {
      this.activeFilter = this.activeFilter.filter((obj) => {
        if (obj.type !== 'where') {
          return obj;
        }
      });
      if (this.filterForm.value[filterName]) {
        this.activeFilter.push({
          type: 'where',
          label: 'SEARCH.FORM.WHERE.LABEL',
          value: this.filterForm.value[filterName],
        });
      }
    } else if (filterName === 'distance') {
      this.activeFilter = this.activeFilter.filter((obj) => {
        if (obj.type !== 'distance') {
          return obj;
        }
      });
      if (this.filterForm.value[filterName]) {
        this.activeFilter.push({
          type: 'distance',
          label: 'SEARCH.FORM.DISTANCE.LABEL',
          value: this.filterForm.value[filterName],
        });
      }
    } else if (filterName === 'region') {
      this.activeFilter = this.activeFilter.filter((obj) => {
        if (obj.type !== 'region') {
          return obj;
        }
      });
      if (this.filterForm.value[filterName]) {
        this.activeFilter.push({
          type: 'region',
          label: 'SEARCH.FORM.REGION.LABEL',
          value: this.filterForm.value[filterName].des,
        });
      }
    } else if (filterName === 'sector') {
      this.activeFilter = this.activeFilter.filter((obj) => {
        if (obj.type !== 'sector') {
          return obj;
        }
      });
      if (this.filterForm.value[filterName]) {
        this.activeFilter.push({
          type: 'sector',
          label: 'SEARCH.FORM.SECTOR.LABEL',
          value: this.filterForm.value[filterName].des,
        });
      }
    }
    this.pageNumber = 0;
    this.search();
  }

  resetPartenerSearch() {
    this.partenerUuid = '';
    this.currentPartener = null;
    this.router.navigate(['private', 'candidato', 'home']);
  }

  toComplete(): void {
    this.router.navigate(['private', 'candidato', 'utente', 'user-profile']);
  }

  pagerChange(data) {
    this.pageNumber = data;
    this.search();
  }

  goToSuggestedDetail(id) {
    this.router.navigate([
      'private',
      'candidato',
      'cerca-annunci',
      'search-detail',
      id,
    ]);
  }

  registerOverlay() {
    this.dialogs
      .open(
        new PolymorpheusComponent(
          GeneralRegisterOverlayComponent,
          this.injector
        )
      )
      .subscribe(() => this.router.navigate(['auth', 'register', 'general']));
  }

  ngOnDestroy(): void {
    this.clearAllFilters();
  }
}
