import { TitleCasePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StatoEnum } from 'src/app/core/auth/enum/stato.enum';
import { GenderTypeEnum } from 'src/app/core/auth/interfaces/company.model';
import { RoleType, UserType } from 'src/app/core/auth/interfaces/role.model';
import { UserService } from 'src/app/core/auth/services/user.service';
import { UtilityService } from 'src/app/core/utility.service';
import { SuggestedWorker } from 'src/app/pages/private/pages/company/pages/my-announcements/pages/candidates/model/suggested-worker.model';
import { Form, SelectInput, TextInput } from 'src/app/shared/form';
import { SliderInput } from 'src/app/shared/form/models/inputs/slider-input';
import {
  SearchWorkersResponse,
  Worker
} from 'src/app/shared/interfaces/search-workers.model';
import {
  FilterOrderBy,
  SearchWorkersFiltersResponse
} from '../../../interfaces/search-filters';
import { SearchCandidatesFilterService } from '../../../services/search-candidates-filter.service';
import { SearchCandidateService } from '../../../services/search-candidates.service';
import { GeneralRegisterOverlayComponent } from '../../overlay/general-register-overlay/general-register-overlay.component';
import { OverlayService } from '../../overlay/overlay.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-search-candidates',
  templateUrl: './search-candidates.component.html',
  styleUrls: ['./search-candidates.component.scss'],
})
export class SearchCandidatesComponent implements OnInit {
  @Input() isPublic: boolean = false;
  @ViewChild('completeProfileDialog') completeProfile: TemplateRef<any>;
  @ViewChild('noResult') noResult: TemplateRef<any>;
  @ViewChild('noOffers') noOffers: TemplateRef<any>;
  @ViewChild('candidateList')
  candidateListRef: TemplateRef<ElementRef>;

  filterForm: Form;
  activeFilter: Array<any> = [];
  presetFilter: any;
  additionalFilters: boolean = false;
  filter: SearchWorkersFiltersResponse;
  sortedLanguages: string[] = [];
  candidateList: Worker[] = null;
  suggestedList: SuggestedWorker[] = [];
  cities = this.utilityService.sortCities(this.utilityService.getCitiesList());
  keyword = false;

  GenderTypeEnum = GenderTypeEnum;
  StatoEnum = StatoEnum;
  roleType = RoleType;
  OrderBy = FilterOrderBy;
  userStatus: { role: string; stato: string };

  user: any;
  suspendSearch: boolean;
  enqueuedChanges: boolean;

  pageNumber = 0;
  size = 10;
  length = 0;
  totalElements = 0;
  totalMatches = 0;

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    private i18n: TranslateService,
    private router: Router,
    private searchFilterService: SearchCandidatesFilterService,
    private utilityService: UtilityService,
    private searchService: SearchCandidateService,
    private loading: NgxSpinnerService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    public userService: UserService,
    private titleCasePipe: TitleCasePipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.getUser();

    this.route.data.subscribe(
      (data: {
        searchInfo: { filters: SearchWorkersFiltersResponse };
        suggestedWorkers: SuggestedWorker[];
      }) => {
        this.suggestedList = data?.suggestedWorkers;
        this.filter = data.searchInfo.filters;
        this.sortedLanguages = this.utilityService.sortLanguages([
          ...this.filter.language,
        ]);
        this.suspendAndAccumulateRequests();
        this._initFilterForm();
        this.resumeRequests();
        this.presetFilter = this.searchFilterService.getFilterList();
        this.presetFilters();
      }
    );
  }

  private _initFilterForm() {
    this.filterForm = new Form({
      header: {
        show: false,
      },
      controls: {
        what: new TextInput({
          type: 'text',
          label: 'SEARCH.FORM.WHAT.LABEL',
          placeholder: 'SEARCH.FORM.WHAT.PLACEHOLDER',
          pattern: /.*\S.*/,
        }),
        where: new SelectInput<string, string>({
          label: 'SEARCH.FORM.WHERE_MUNICIPALITY.LABEL',
          placeholder: 'SEARCH.FORM.WHERE_MUNICIPALITY.PLACEHOLDER',
          options: of(this.cities),
          bindValue: false,
          valueChange: (value: any) => {
            if (value?.cod) {
              this.filterForm.get('distance').enable();
              this.filterForm
                .get('distance')
                .setValue(10, { emitEvent: false });
              this.filterForm
                .get('where')
                .setValue(value, { emitEvent: false });
              this.changeFilters('where');
            }
          },
        }),
        distance: new SliderInput({
          label: 'HOME.SEARCH.DISTANCE',
          min: 1,
          max: 100,
          value: 10,
          segments: 1,
          showCurrentValue: true,
          readonly: true,
          currentValueLabelEnd: 'UTILS.MISURA_DISTANZA',
        }),
        extraKeyword: new TextInput({
          type: 'text',
          label: 'SEARCH.FORM.EXTRA_KEYWORD.LABEL',
          placeholder: 'SEARCH.FORM.EXTRA_KEYWORD.PLACEHOLDER',
          pattern: /.*\S.*/,
        }),
        educationLevel: new SelectInput<string, string>({
          label: 'SEARCH.FORM.EDUCATION_LEVEL.LABEL',
          placeholder: 'SEARCH.FORM.EDUCATION_LEVEL.PLACEHOLDER',
          options: of(this.utilityService.getEducationLevels()),
          bindValue: false,
          valueChange: (cod) => {
            if (cod) {
              this.filterForm
                .get('educationLevel')
                .setValue(cod, { emitEvent: false });
              this.changeFilters('educationLevel');
            }
          },
        }),
        language: new SelectInput<string, string>({
          label: 'SEARCH.FORM.LENGUAGE.LABEL',
          placeholder: 'SEARCH.FORM.LENGUAGE.PLACEHOLDER',
          options: of(
            this.sortedLanguages.map((len) => ({
              cod: len,
              des: this.i18n.instant('MAPPINGS.languages')[len],
            }))
          ),
          bindValue: false,
          valueChange: (cod) => {
            if (cod) {
              this.filterForm
                .get('language')
                .setValue(cod, { emitEvent: false });
              this.filterForm.get('languageLevel').enable();
              this.filterForm.get('languageLevel').required = true;
            }
          },
        }),
        languageLevel: new SelectInput<string, string>({
          label: 'SEARCH.FORM.LENGUAGE_LEVEL.LABEL',
          placeholder: 'SEARCH.FORM.LENGUAGE_LEVEL.PLACEHOLDER',
          options: of(
            this.filter.languageLevel.map((len) => ({
              cod: len,
              des: this.titleCasePipe.transform(len),
            }))
          ),
          bindValue: false,
          valueChange: (cod) => {
            if (cod) {
              this.filterForm
                .get('languageLevel')
                .setValue(cod, { emitEvent: false });
              this.changeFilters('languageLevel');
            }
          },
        }),
        previousExperiencesInInplace: new SelectInput<boolean, string>({
          label: 'SEARCH.FORM.EXPERIENCE_IN_INPLACE.LABEL',
          placeholder: 'SEARCH.FORM.EXPIRIENCE.PLACEHOLDER',
          options: of([
            {
              cod: true,
              des: 'Sì',
            },
            {
              cod: false,
              des: 'No',
            },
          ]),
          bindValue: false,
          valueChange: (cod) => {
            if (cod) {
              this.filterForm
                .get('previousExperiencesInInplace')
                .setValue(cod, { emitEvent: false });
              this.changeFilters('previousExperiencesInInplace');
            }
          },
        }),
        sector: new SelectInput<string, string>({
          label: 'SEARCH.FORM.SECTOR.LABEL',
          placeholder: 'SEARCH.FORM.SECTOR.PLACEHOLDER',
          options: of(
            this.filter.sector.map((sec) => ({
              cod: sec,
              des: this.i18n.instant('MAPPINGS.sectors')[sec],
            }))
          ),
          bindValue: false,
          valueChange: (cod) => {
            if (cod) {
              this.filterForm.get('sector').setValue(cod, { emitEvent: false });
              this.changeFilters('sector');
            }
          },
        }),
        status: new SelectInput<boolean, string>({
          label: 'SEARCH.FILTER.AVAILABLE',
          placeholder: 'SEARCH.FORM.STATUS.PLACEHOLDER',
          options: of([
            {
              cod: true,
              des: this.i18n.instant('SEARCH.INFO.HIRED'),
            },
            {
              cod: false,
              des: this.i18n.instant('SEARCH.INFO.NOT_HIRED'),
            },
          ]),
          bindValue: false,
          valueChange: (cod) => {
            if (cod) {
              this.filterForm.get('status').setValue(cod, { emitEvent: false });
              this.changeFilters('status');
            }
          },
        }),
        orderBy: new SelectInput<FilterOrderBy, string>({
          label: 'SEARCH.FORM.ORDERBY.LABEL',
          options: of([
            {
              cod: FilterOrderBy.COMPATIBILITA,
              des: 'Compatibilità',
            },
            {
              cod: FilterOrderBy.DATA,
              des: 'Data',
            },
            {
              cod: FilterOrderBy.DISTANZA,
              des: 'Distanza',
            },
            {
              cod: FilterOrderBy.AGE,
              des: 'Età',
            },
          ]),
          value: FilterOrderBy.COMPATIBILITA,
          valueChange: (cod) => {
            this.pageNumber = 0;
            this.filterForm.get('orderBy').setValue(cod, { emitEvent: false });
            this.search();
          },
        }),
      },
    });
    this.filterForm.get('languageLevel').disable();
  }

  presetFilters() {
    if (this.presetFilter?.pageNumber) {
      this.pageNumber = this.presetFilter.pageNumber;
      this.pageChange(this.pageNumber);
    }
    if (this.presetFilter?.what) {
      this.filterForm
        .get('what')
        .setValue(this.presetFilter.what, { emitEvent: false });
      this.keyword = true;
      this.changeFilters('what');
    }
    if (this.presetFilter?.where) {
      this.filterForm
        .get('where')
        .setValue(this.presetFilter.where, { emitEvent: false });
      this.changeFilters('where');
    }
    if (this.presetFilter?.distance) {
      this.filterForm.get('distance').enable();
      this.filterForm
        .get('distance')
        .setValue(this.presetFilter.distance, { emitEvent: false });
      this.changeFilters('distance');
    }
    if (this.presetFilter?.extraKeyword) {
      this.filterForm
        .get('extraKeyword')
        .setValue(this.presetFilter.extraKeyword, { emitEvent: false });
      this.changeFilters('extraKeyword');
    }
    if (this.presetFilter?.educationLevel) {
      this.filterForm
        .get('educationLevel')
        .setValue(this.presetFilter.educationLevel, { emitEvent: false });
      this.changeFilters('educationLevel');
    }
    if (this.presetFilter?.language) {
      this.filterForm
        .get('language')
        .setValue(this.presetFilter.language, { emitEvent: false });
      this.filterForm.get('languageLevel').enable();
      this.changeFilters('language');
    }
    if (this.presetFilter?.languageLevel) {
      this.filterForm.get('languageLevel').enable();
      this.filterForm
        .get('languageLevel')
        .setValue(this.presetFilter.languageLevel, { emitEvent: false });
      this.changeFilters('languageLevel');
    }
    if (this.presetFilter?.sector) {
      this.filterForm.get('sector').setValue(this.presetFilter.sector),
        { emitEvent: false };
      this.changeFilters('sector');
    }
    if (this.presetFilter?.previousExperiencesInInplace) {
      this.filterForm
        .get('previousExperiencesInInplace')
        .setValue(this.presetFilter.previousExperiencesInInplace, {
          emitEvent: false,
        });
      this.changeFilters('previousExperiencesInInplace');
    }
    if (this.presetFilter?.status) {
      this.filterForm
        .get('status')
        .setValue(this.presetFilter.status, { emitEvent: false });
      this.changeFilters('status');
    }
    if (this.presetFilter?.orderBy?.length > 0) {
      this.filterForm
        .get('orderBy')
        .setValue(this.presetFilter.orderBy, { emitEvent: false });
    }
    this.search();
    if (this.presetFilter?.pageNumber > 0) {
      this.pageNumber = this.presetFilter.pageNumber;
    }
    this.activeFilter.map((filter) => {
      if (filter?.type !== 'what' && filter?.type !== 'where')
        this.additionalFilters = true;
    });
  }

  setFiltersList(params) {
    this.searchFilterService.setFilterList({
      ...this.filterForm.value,
      pageNumber: this.pageNumber,
    });
    if (this.filterForm.get('educationLevel').value) {
      params['educationLevel'] = this.filterForm.get('educationLevel').value;
    }
    if (
      this.filterForm.get('language').value &&
      this.filterForm.get('languageLevel').value
    ) {
      params['language'] = this.filterForm.get('language').value;
      params['languageLevel'] = this.filterForm.get('languageLevel').value;
    }
    if (this.filterForm.get('previousExperiencesInInplace').value) {
      params['previousExperiencesInInplace'] = this.filterForm.get(
        'previousExperiencesInInplace'
      ).value;
    }
    if (this.filterForm.get('sector').value) {
      params['sector'] = this.filterForm.get('sector').value;
    }
    if (this.filterForm.get('status').value) {
      params['status'] = this.filterForm.get('status').value;
    }
    if (this.filterForm.get('what').value) {
      params['what'] = this.filterForm.get('what').value;
    }
    if (this.filterForm.get('where').value) {
      params['where'] = this.filterForm.get('where').value;
    }
    if (this.filterForm.get('extraKeyword').value) {
      params['extraKeyword'] = this.filterForm.get('extraKeyword').value;
    }
    if (this.filterForm.get('distance').value) {
      params['distance'] = +this.filterForm.get('distance').value;
    }
  }

  changeFilters(filterName: string) {
    switch (filterName) {
      case 'what':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'what') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName]) {
          this.activeFilter.push({
            type: 'what',
            label: 'SEARCH.FORM.WHAT.LABEL',
            value: this.filterForm.value[filterName],
          });
        }
        break;

      case 'where':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'where' && obj.type !== 'distance') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName]) {
          this.activeFilter.push({
            type: 'where',
            label: 'SEARCH.FORM.WHERE.LABEL',
            value: this.filterForm.value[filterName].des,
          });
        }
        break;

      case 'distance':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'distance') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName] !== 10) {
          this.activeFilter.push({
            type: 'distance',
            label: 'HOME.SEARCH.DISTANCE',
            value: this.filterForm.value[filterName] + ' km',
          });
        }
        break;

      case 'extraKeyword':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'extraKeyword') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName]) {
          this.activeFilter.push({
            type: 'extraKeyword',
            label: 'SEARCH.FORM.EXTRA_KEYWORD.LABEL',
            value: this.filterForm.value[filterName],
          });
        }
        break;

      case 'educationLevel':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'educationLevel') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName]) {
          this.activeFilter.push({
            type: 'educationLevel',
            label: 'SEARCH.FORM.EDUCATION_LEVEL.LABEL',
            value: this.filterForm.value[filterName].des,
          });
        }
        break;

      case 'language':
      case 'languageLevel':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'languageLevel' && obj.type !== 'language') {
            return obj;
          }
        });
        if (
          this.filterForm.value['languageLevel'] &&
          this.filterForm.value['language']
        ) {
          this.activeFilter.push(
            {
              type: 'language',
              label: 'SEARCH.FORM.LENGUAGE.LABEL',
              value: this.filterForm.value['language'].des,
            },
            {
              type: 'languageLevel',
              label: 'SEARCH.FORM.LENGUAGE_LEVEL.LABEL',
              value: this.filterForm.value['languageLevel'].des,
            }
          );
        }
        break;

      case 'previousExperiencesInInplace':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'previousExperiencesInInplace') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName]) {
          this.activeFilter.push({
            type: 'previousExperiencesInInplace',
            label: 'SEARCH.FORM.EXPERIENCE_IN_INPLACE.LABEL',
            value: this.filterForm.value[filterName].des,
          });
        }
        break;

      case 'sector':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'sector') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName]) {
          this.activeFilter.push({
            type: 'sector',
            label: 'SEARCH.FORM.SECTOR.LABEL',
            value: this.filterForm.value[filterName].des,
          });
        }
        break;

      case 'status':
        this.activeFilter = this.activeFilter.filter((obj) => {
          if (obj.type !== 'status') {
            return obj;
          }
        });
        if (this.filterForm.value[filterName]) {
          this.activeFilter.push({
            type: 'status',
            label: 'SEARCH.FORM.STATUS.LABEL',
            value: this.filterForm.value[filterName].des,
          });
        }
        break;

      case 'orderBy':
        if (this.filterForm.value[filterName]) {
          this.setFiltersList({
            ...this.filterForm.value,
            orderBy: this.filterForm.get('orderBy').value,
          });
        }
        break;

      default:
        break;
    }
  }

  clearFilter(filter) {
    switch (filter.type) {
      case 'orderBy':
        this.filterForm
          .get(filter.type)
          .setValue(FilterOrderBy.COMPATIBILITA, { emitEvent: false });
        this.changeFilters(filter.type);
        break;

      case 'what':
        this.keyword = false;
        this.filterForm.get(filter.type).setValue('', { emitEvent: false });
        this.changeFilters(filter.type);
        break;

      case 'where':
        this.filterForm.get('distance').disable();
        this.filterForm.get('distance').setValue(10, { emitEvent: false });
        this.filterForm.get(filter.type).setValue('', { emitEvent: false });
        this.changeFilters(filter.type);
        break;

      case 'distance':
        this.filterForm.get('distance').setValue(10, { emitEvent: false });
        if (!this.filterForm.get('where').value) {
          this.filterForm.get('distance').disable();
        }
        this.changeFilters(filter.type);
        break;

      case 'language':
      case 'languageLevel':
        this.filterForm.get('language').setValue('', { emitEvent: false });
        this.filterForm.get('languageLevel').setValue('', { emitEvent: false });
        this.filterForm.get('languageLevel').disable();
        this.changeFilters('languageLevel');
        break;

      default:
        this.filterForm.get(filter.type).setValue('', { emitEvent: false });
        this.changeFilters(filter.type);
        break;
    }

    this.searchFilterService.setFilterList({
      ...this.filterForm.value,
    });
    this.search();
  }

  clearAllFilters(): void {
    this.filterForm.get('what').setValue('', { emitEvent: false });
    this.filterForm.get('where').setValue('', { emitEvent: false });
    this.filterForm.get('educationLevel').setValue('', { emitEvent: false });
    this.filterForm.get('language').setValue('', { emitEvent: false });
    this.filterForm.get('languageLevel').setValue('', { emitEvent: false });
    this.filterForm
      .get('previousExperiencesInInplace')
      .setValue('', { emitEvent: false });
    this.filterForm.get('sector').setValue('', { emitEvent: false });
    this.filterForm.get('status').setValue('', { emitEvent: false });
    this.filterForm.get('extraKeyword').setValue('', { emitEvent: false });
    this.filterForm
      .get('orderBy')
      .setValue(FilterOrderBy.COMPATIBILITA, { emitEvent: false });
    this.filterForm.get('distance').setValue(10, { emitEvent: false });
    this.activeFilter = [];
    this.keyword = false;
    this.searchFilterService.setFilterList({
      ...this.filterForm.value,
      pageNumber: this.pageNumber,
    });
    this.pageNumber = 0;
    this.search();
  }

  onSubmit() {
    if (this.filterForm.get('what').value) {
      this.keyword = true;
      this.changeFilters('what');
    }
    if (this.filterForm.get('extraKeyword').value) {
      this.changeFilters('extraKeyword');
    }
    if (
      this.filterForm.get('where').value &&
      this.filterForm.get('distance').value &&
      +this.filterForm.get('distance').value !== 10
    ) {
      this.changeFilters('distance');
    }
    this.search();
  }

  search(): void {
    this.pageNumber = 0;
    this.loading.show();
    const params = {
      orderBy: this.filterForm.get('orderBy').value,
      ...this.filterForm.value,
    };
    this.setFiltersList(params);

    if (params['what'] || params['where']) {
      this.searchService
        .searchWorkers(params, this.isPublic)
        .pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((resp: SearchWorkersResponse) => {
          this.candidateList = resp.workers;
          this.totalElements = resp.workers.length;
          this.totalMatches = resp.total;
          this.length = this.getTotalPages();
        });
    } else {
      this.loading.hide();
      this.candidateList = null;
      this.totalElements = 0;
      this.totalMatches = 0;
    }
  }

  goToDetail(id: number): void {
    const requestRoute = [
      'private',
      'azienda',
      'cerca-candidati',
      'search-detail',
      id,
    ]
    if (this.isPublic) {
      this.userService.setRequestedRoute(requestRoute)
      this.overlayService.generalLoginOverlayOpen();
    } else {
      this.searchFilterService.setFilterList({
        ...this.filterForm.value,
        pageNumber: this.pageNumber,
      });
      this.router.navigate(requestRoute);
    }
  }

  downloadDossier(candidateId: number, name: string) {
    this.loading.show();
    this.searchService.getCandidateDossier(candidateId, name).subscribe(
      () => {
        this.loading.hide();
      }
    );
  }

  availabilityRequest(id: number): void {
    const requestRoute = [
      'private',
      'azienda',
      'cerca-candidati',
      'availability',
      id,
    ]
    if (this.isPublic) {
      this.userService.setRequestedRoute(requestRoute, { queryParams: { partener: 'ciccio' } })
      this.overlayService.generalLoginOverlayOpen();
    } else {
      this.router.navigate(requestRoute);
    }
  }

  goToSearch = (keyword: string, address: string) => {
    this.filterForm.get('what').setValue(keyword);
    this.filterForm.get('where').setValue(address);
    this.searchFilterService.setFilterList({
      pageNumber: this.pageNumber,
      ...this.filterForm.value,
    });
    this.pageNumber = 0;
    this.search();
  };

  suspendAndAccumulateRequests(): void {
    this.suspendSearch = true;
  }

  resumeRequests(): void {
    if (this.enqueuedChanges) {
      this.enqueuedChanges = false;
      this.search();
    }
    this.suspendSearch = false;
  }

  toComplete(): void {
    const requestRoute = ['private', 'azienda', 'utente', 'edit-profile']
    if (this.isPublic) {
      this.userService.setRequestedRoute(requestRoute)
      this.overlayService.generalLoginOverlayOpen();
    } else {
      this.router.navigate(requestRoute);
    }
  }

  toLogin(id) {
  //  this.overlayService.generalLoginOverlayOpen();
    this.goToDetail(id)
  }

  registerOverlay() {
    this.dialogs
      .open(
        new PolymorpheusComponent(
          GeneralRegisterOverlayComponent,
          this.injector
        )
      )
      .subscribe(() => this.router.navigate(['auth', 'register', 'general']));
  }

  goToCreateAnnouncement() {
    const requestRoute = [
        'private',
        'azienda',
        'annunci',
        'gestione-annunci',
        'create',
      ]
    if (this.isPublic) {
      this.userService.setRequestedRoute(requestRoute)
      this.overlayService.generalLoginOverlayOpen();
    } else {
      this.router.navigate(requestRoute);
    }
  }

  getTotalPages() {
    return this.isPublic
      ? this.totalMatches > 300
        ? Math.ceil(300 / this.size)
        : Math.ceil(this.totalMatches / this.size)
      : Math.ceil(this.totalElements / this.size);
  }

  pageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    document.body.scrollTo(0, 0);
  }

  toRegister() {
    this.userService.setRequestedRoute(["private", "azienda", "annunci", "gestione-annunci", "create"])
    this.router.navigate(['auth/register/company'], {
      queryParams: { userType: UserType.COMPANY },
    });

  }

  getAddress(address) {
    if(!address?.municipality && !address?.municipality) {
      return this.translate.instant('SEARCH.FORM.NO_ADDRESS');
    }

    if (address.country !== 'ITALIA') {

      let addr = address.municipality;

      if(address.region) {
        addr += ` - ${address.region}`;
      }

      addr += `, ${this.translate.instant('MAPPINGS.countries.' + address.country)}`;

      return addr;
    }

    return `${this.titleCasePipe.transform(this.translate.instant('MAPPINGS.municipalities.' + address.municipality))} - ${this.translate.instant('MAPPINGS.regions.' + address.region)}, ${this.translate.instant('MAPPINGS.countries.' + address.country)}`;;
  }
}
